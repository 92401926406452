import * as React from 'react';
import { ButtonLink } from '../components/v1/atoms/Button';
import useStoryblok from '../lib/storyblok';
import { graphql } from 'gatsby';
import { StoryblokPage } from '../types';
import Layout from '../components/v2/templates/Layout';

const NotFoundPage: StoryblokPage = ({ location, data }) => {
  let story = useStoryblok(data.storyblokEntry, location);
  const { content } = story;

  return (
    <Layout
      location={location}
      headerData={content.header}
      mainClassName="overflow-hidden min-h-fit"
      hideFooter
    >
      <div
        style={{ minHeight: 'calc(100vh - 96px' }}
        className="flex items-center justify-center relative w-full max-w-7xl m-auto"
      >
        <div className="flex flex-col items-center justify-center z-20">
          <h1 className="text-8xl font-serif">404</h1>
          <p className="text-2rem p-12 mb-6">Oops! The page you are looking for is not available</p>
          <ButtonLink type="secondary" toPage="/" label="Back to home" />
        </div>
        <svg
          className="absolute -right-60 top-2 w-full h-full"
          width="402"
          height="675"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M178.378 169.224c67.574-32.128 153.251-43.242 241.28-38.872 88.008 4.369 178.205 24.208 254.717 53.833 76.571 29.647 139.182 68.997 172.288 112.215 16.531 21.581 25.624 44.017 25.566 66.631-.057 22.588-9.243 45.627-29.786 68.445-41.385 45.965-81.493 69.326-120.883 80.776-39.441 11.466-78.299 11.036-117.337 9.186-5.169-.245-10.341-.516-15.517-.786-72.918-3.81-146.554-7.657-223.609 58.265-81.126 69.404-172.891 60.883-228.613 18.565-27.881-21.175-46.699-50.768-50.782-83.294-4.078-32.48 6.504-68.137 37.892-101.575 15.998-17.043 24.755-31.801 28.161-44.877 3.426-13.149 1.401-24.452-3.865-34.412-5.229-9.891-13.62-18.397-22.838-26.12-5.84-4.893-12.142-9.571-18.24-14.097-3.476-2.58-6.885-5.111-10.106-7.605-8.996-6.967-16.605-13.703-21.015-20.844-4.324-6.999-5.553-14.335-1.844-22.826 3.791-8.677 12.819-18.742 29.6-30.743 16.74-11.971 40.993-25.729 74.931-41.865z"
            stroke="#FFF2FC"
            strokeWidth="4"
          />
        </svg>
        <svg
          className="absolute -right-60 top-2 w-full h-full"
          width="412"
          height="802"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M829.157 263.368c76.237 155.522 45.407 355.651-23.788 480.392-34.627 62.423-78.568 105.443-122.988 114.972-22.121 4.745-44.467 1.215-66.07-12.504-21.651-13.749-42.643-37.794-61.821-74.212-38.563-73.228-69.226-126.234-96.913-163.978-27.692-37.75-52.519-60.39-79.469-72.696-26.968-12.315-55.812-14.167-91.279-10.839-24.118 2.263-51.463 6.943-83.482 12.424-14.971 2.562-30.963 5.299-48.125 8.046-26.606 4.258-45.117-.951-57.185-12.554-12.132-11.665-18.166-30.179-18.909-53.397-1.487-46.445 18.253-110.406 49.859-171.157 31.593-60.725 74.851-117.873 120.051-150.8 22.594-16.459 45.544-26.772 67.668-28.584 22.048-1.806 43.444 4.813 63.095 22.544 40.602 36.632 79.836 42.376 118.109 35.231 19.062-3.559 37.855-10.306 56.404-17.936 8.126-3.342 16.146-6.827 24.126-10.295 10.367-4.505 20.665-8.98 31.039-13.074 36.539-14.422 72.328-23.38 108.41-9.728 36.112 13.665 73.053 50.188 111.268 128.145z"
            stroke="#FFF2FC"
            strokeWidth="4"
          />
        </svg>
      </div>
    </Layout>
  );
};

export default NotFoundPage;

export const query = graphql`
  query FourOhFourQuery {
    storyblokEntry(full_slug: { eq: "404" }) {
      content
      name
    }
  }
`;
